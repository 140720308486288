<template>
  <div class="bg">
    <div class="header">
      <div class="headerL">
        <router-link to="/">
          <img src="@/assets/image/logo.png" alt="logo" />
        </router-link>
      </div>
      <div class="title">外研教学评系统隐私政策</div>
    </div>
    <div class="main">
      <p>生效日期：2023年7月 24 日</p>
      <br />
      <div class="h1">引言</div>
      <p>
        尊敬的用户，欢迎您使用外研教学评系统（以下简称“外研教学评系统”、“本产品”，外研教学评旨在为教师用户提供英语阅读教学支持、认证、反馈服务，包含外研教学评网站https://unilearn.fltrp.com以及相配套的悦测评、悦教大课堂、优诊学、iWay英语能力等级测试系统、外研拼读等产品）。外语教学与研究出版社有限责任公司（下称“我们”）尊重并保护所有使用本产品的用户（以下亦简称“您”）的个人信息和隐私保护，为此我们制定了本隐私政策。在您使用我们的服务时，我们将按照本隐私权政策的规定处理您的个人信息。同时，我们会通过本政策向您说明，我们如何为您提供访问、更新、管理和保护您的信息的服务。
      </p>
      <br />
      <p class="underL weight">
        在使用本产品前，请您务必仔细阅读并理解本政策，特别是加粗和/或下划线的内容，因为这些内容可能会对您的权利产生重要影响。如您不同意本政策中的任何条款，您应立即停止使用本产品，如对本政策内容有任何疑问、意见或建议，您可通过文末提供的各种联系方式与我们联系。您表示同意或注册使用本产品，即意味着您知晓且同意本政策。
      </p>
      <br />
      <div>
        <span class="weight underL">
          特别提醒，我们不接受未满十四周岁的未成年人自行注册成为用户，如果您是未满十四周岁的未成年人，请您请您的监护人注册我们的产品，并在监护人的陪同下阅读本政策及
          <router-link target="_blank" to="/childrenPrivacy">
            《儿童隐私保护声明》
          </router-link>
          ，
        </span>
        <span> 您在您的监护人的监护下使用我们的产品； </span>
        <span class="weight underL">
          如果您是未满十四周岁的未成年人且您是通过学校提供的账号使用我们的产品的，则您的个人信息将由您的学校提供给我们，我们根据您的学校的要求处理您的个人信息，请您在学校的老师的陪同下阅读本政策及《儿童隐私保护声明》。
        </span>
      </div>
      <br />
      <div class="weight underL">
        本政策适用于外研教学评的产品及服务（包括但不限于网站，以及未来可能存在的各种形态）。
      </div>
      <br />

      <p class="weight">本政策将帮助您了解到以下信息：</p>
      <p>一、我们如何收集和使用个人信息</p>
      <p>二、我们如何使用Cookies或同类技术</p>
      <p>三、我们如何委托处理、共享、转让、公开披露您的个人信息</p>
      <p>四、我们如何保护您的个人信息</p>
      <p>五、您对您的个人信息的权利</p>
      <p>六、个人信息的储存</p>
      <p>七、第三方服务提供商及其服务</p>
      <p>八、我们如何保护未成年人的信息</p>
      <p>九、本政策如何更新</p>
      <p>十、如何联系我们</p>
      <p>十一、相关术语与定义</p>
      <br />
      <div class="weight">一、我们如何收集和使用个人信息</div>
      <p>
        在您使用外研教学评的过程中，我们会在合法、正当、最小必要的原则下，基于本政策所述目的，按照如下方式收集您在使用服务时主动提供或因为使用服务而产生的信息，用以向您提供服务、优化我们的服务以及保障您的账号安全。我们不会收集与提供服务无关的个人信息，也不会因您不同意收集非必要个人信息，而拒绝您使用本产品的基本功能服务。如果我们将您的个人信息用于本政策未载明的其它用途，或基于其他特定目的而收集、使用您的个人信息，我们将以合理的方式告知您，并在收集、使用前再次征得您的同意。
      </p>
      <p>
        在收集您的个人信息时，我们会以三种方式进行收集，主要包括您在使用本产品时主动向我们提供，我们通过自动化手段采集您在使用过程中产生的信息，以及我们通过合法共享、转让、搜集公开信息等间接方式获取您的个人信息的方式。为行文简洁，我们在以下相关服务和功能中不另外赘述。
      </p>
      <div class="weight">
        1.在使用外研教学评的过程中，我们向您收集个人信息的场景
      </div>
      <p class="weight pl1">1.1.用户注册与登录</p>
      <p class="pl1">
        1.1.1.如果您是自行注册成为用户的，<span class="weight underL"
          >为了创建账号，</span
        >我们需要收集您的<span class="weight underL"
          >手机号码、密码、验证码，如您不提供以上信息，您将无法注册我们的产品；为完成账号登录，我们需要收集您的账号或手机号或邮箱、密码，</span
        >如果您拒绝提供此类信息，则无法完成账号注册，也无法登录使用本产品。
      </p>
      <p class="pl1 underL">
        1.1.2.如果您是教师用户，您可以在登录本产品悦教大讲堂模块后
        <span class="weight"
          >补充您的个人信息，包括性别、生日、所教学段、单位、工作年限、关注领域。</span
        >如果您不提供此类信息，将不会影响您使用外研教学评的基本功能，但与该等信息相关的一些扩展性功能可能会无法使用。
      </p>
      <p class="weight pl1">
        1.1.3.如果您是教师用户，为了帮助您进行班级管理、考务管理，方便您安排考试，进行作答数据的统计分析，并生成报告等，或进行相关教学学习，<span
          class="underL"
          >我们需要收集您的姓名、班级、年级、学校。</span
        >如果您拒绝提供此类信息，可能无法使用相应的功能，但不会影响您使用外研教学评的基本功能。
      </p>
      <p class="underL pl1">
        1.1.4.如果您作为教师管理员，需要为学校的学生统一生成学生的个人账号的，您需要按照页面提示
        <span class="weight">提供学生姓名、学校名称、班级、所属年级，</span
        >方能生成学生的个人账号。
        <span class="weight"
          >您需要在收集学生个人信息前征得其及其监护人的同意。</span
        >如果您拒绝提供此类信息，则无法完成学生账号的注册，学生将无法使用该账号登录使用本产品。
      </p>
      <p class="pl1">
        1.1.5.如果您是学生用户，且是通过学校给您分配账号从而登录本产品成为用户的，<span
          class="weight underL"
        >
          您知悉并认可，在您登录本产品前您所在的学校工作人员已将您的姓名、班级、学校名称、所属年级、专业等个人信息提供给我们，由我们导入系统中，以确保您能正常登录并使用本产品，您同意本隐私政策，即代表您同意并追认同意学校有权提供您的个人信息给我们，我们可依本政策的约定处理您的个人信息。
        </span>
      </p>
      <p class="weight pl1">1.2.找回密码</p>
      <p class="pl1">
        为找回您的密码，我们需要收集您的<span class="weight underL"
          >手机号码或邮箱、验证码，</span
        >如您不能提供，我们将无法为您找回密码。
      </p>
      <p class="weight pl1">1.3.下单与支付</p>
      <p class="pl1">
        1.3.1.为了向您提供网络支付购买付费服务，校验下单账号和支付账号是否一致，我们会收集您的微信账号和支付宝账号，但不会存储您的这些账号。
      </p>
      <p class="pl1">
        1.3.2.为了确保交易安全，保障购买成功，我们会向第三方支付机构共享您的头像、账号名、订单支付相关信息、设备信息、系统信息及网络信息，如需全面了解我们与第三方共享您个人信息的情况，您可查看文末的附件《外研教学评个人信息第三方共享清单》。
      </p>
      <p class="weight pl1">1.4.客服、反馈及其他功能</p>
      <p class="pl1">
        1.4.1.为了保障您的账号与系统安全，解决您在使用我们产品过程中的问题时，我们会保存您所反馈的问题记录，包括
        <span class="weight underL"
          >账号信息（用户ID)、反馈信息（文字、图片、文件）。</span
        >
      </p>
      <p class="pl1">
        1.4.2.如果是为了向您提供售后服务，确保您向客服反馈的信息能准确有效处理，我们还需要收集您的账号信息（用户ID)、
        <span class="weight underL">订单信息、联系方式（手机号码）。</span>
      </p>
      <p class="weight pl1">1.5.运营和产品安全维护</p>
      <p class="pl1">
        1.5.1我们将收集您的如下信息，以便为您提供持续稳定的服务支持（如数据的实时同步等），并识别账号异常状态、了解产品适配性、保障本产品服务的网络及运营安全，以维护前述基本功能的正常运行。我们会收集您的
        <span class="weight underL"
          >设备信息（包括设备型号、设备类型、设备序列号、设备识别码（MAC地址等）、设备厂商、操作系统版本、应用列表、设备设置基本信息）、日志信息（User-Agent、IP地址、访问日期和时间、您访问的记录）、网络信息（包括使用的移动网络运营商网络数据信息、WI-FI信号信息、宽带数据信息）。</span
        >
      </p>
      <p class="pl1">
        <span class="weight underL"
          >1.5.2请注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。</span
        >
        如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
      </p>
      <p class="pl1">
        1.6.当您使用本产品网站或移动端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。包括您的搜索和浏览记录、学习记录、测试记录、测试结果、IP地址、WIFI网络、访问日期和时间等。
      </p>
      <div class="weight">
        2.当您使用以下功能时，我们需要您提供相应的权限许可
      </div>
      <p>
        2.1.当您需要使用口语测评功能的，我们需要获取<span class="underL"
          ><span class="weight">您的麦克风权限，</span
          >帮助您进行口语测评、参加测评考试。
        </span>
      </p>
      <div class="weight">3.除同意之外的其他合法性事由</div>
      <p>
        依据相关法律法规及国家标准，在以下情形中，我们可能会依法收集、使用您的个人信息而无需征求您的授权同意：<br />
        （1）为订立、履行您作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；<br />
        （2）为履行法定职责或者法定义务所必需；<br />
        （3）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；<br />
        （4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；<br />
        （5）依照相关法律规定在合理的范围内处理您自行公开或者其他已经合法公开的个人信息，对您的个人权益有重大影响或您明确拒绝的除外；<br />
        （6）法律、行政法规规定的其他情形。
      </p>
      <div class="weight">二、我们如何使用Cookies或同类技术</div>
      <p>
        1.为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、跟踪您的学习数据，进而为您提供更优质、更个性化的用户体验，帮助判断您的登录状态以及账号或数据安全。
      </p>
      <p>
        2.我们不会将Cookies用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookies。您可以清除计算机上保存的所有Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问产品网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。
      </p>
      <p>
        3.关于Cookie的详细信息以及关于如何设置您的浏览器来接受、删除或禁用Cookie的说明，请参阅www.allaboutcookies.org。
      </p>
      <p>
        4.请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善我们的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向您通知并征得您的同意。
      </p>
      <div class="weight">
        三、我们如何委托处理、共享、转让、公开披露您的个人信息
      </div>
      <p class="weight">(一)委托处理</p>
      <p>
        本产品功能中某些具体的模块或功能可能由外部供应商提供。对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协定，并与其约定委托处理的目的、期限、处理方式、个人信息的种类、保护措施以及双方的权利和义务等，我们会监督其个人信息处理活动，要求他们按照我们的要求、本政策以及其他任何相关的保密和安全措施来处理个人信息。
      </p>
      <p class="weight">(二)共享</p>
      <p>
        在我们向您提供本产品时，除以下情形外，我们不会与外研社以外的任何公司、组织和个人共享您的个人信息。
      </p>
      <p>1．在获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
      <p>
        2．我们可能会根据法律法规规定，或按政府主管部门的强制性要求，
        对外共享您的个人信息。
      </p>
      <p>
        3．我们可能会向第三方共享您的个人信息，以保障为您提供的服务顺利完成。目前，我们的对外提供您的个人信息的第三方包括：第三方技术供应商、第三方服务提供商。我们将信息发送给在中国境内支持我们业务的第三方技术供应商、第三方服务提供商，这些支持包括提供登录方便、支付便利、实现软件维护、推送与您相关的信息。但我们仅会在本政策所声明的范围内，出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。与我们合作的第三方无权将共享的个人信息用于任何其他用途，并应对您的信息采取保护措施，并严格遵守相关法律法规及监管要求。我们会对第三方获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与第三方约定严格的数据保护措施，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息，以保障您的个人信息安全。
      </p>
      <p>
        如需全面了解第三方收集和使用您个人信息的情况，您可查看文末的附件《外研教学评个人信息第三方共享清单》。
      </p>
      <p class="weight">(三)转让</p>
      <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
      <p class="weight underL">1. 事先获得您明确的同意或授权；</p>
      <p class="weight underL">
        2.
        在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织向您征求授权同意并继续受本政策的约束。
      </p>
      <p class="weight">(四)公开披露</p>
      <p>
        我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
      </p>
      <p>1. 在您明确同意的披露方式下披露您所指定的个人信息；</p>
      <p>
        2.
        根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。
      </p>
      <p class="weight">
        (五)共享、转让、公开披露个人信息时事先征得授权同意的例外
      </p>
      <p>
        以下情形中，我们在共享、转让、公开披露个人信息不必事先征得您的授权同意：
      </p>
      <p>（1）与我们履行法律法规规定的义务相关的；</p>
      <p>（2）与国家安全、国防安全直接相关的；</p>
      <p>（3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>（4）与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>
        （5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
      </p>
      <p>（6）您自行向社会公众公开的个人信息；</p>
      <p>
        （7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      </p>
      <div class="weight">四、我们如何保护您的个人信息</div>
      <p>
        我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息，并确保与本政策所述目的无关的个人信息不被收集：
      </p>
      <p class="weight">1.数据安全技术措施</p>
      <p>
        我们会采用符合业界标准的安全防护措施，包括在信息传输、保存过程中使用加密技术（例如：SSL）、去标识化/匿名化、建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。我们的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，在您的移动设备与产品和/或服务之间交换数据时进行SSL加密保护，确保用户数据在传输过程中的安全。
      </p>
      <p>
        我们在信息安全方面已经达到ISO27001、国际信息安全管理体系、TRUSTArc认证标准的要求，并进行了等级三级保护。
      </p>
      <p class="weight">2.为保护个人信息采取的其他安全措施</p>
      <p>
        我们仅允许有必要知晓这些信息的我们的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制，如部署访问控制机制，确保只有授权人员才可访问个人信息。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务，对可能接触到用户信息的员工或外包人员也采取严格管理，包括但不限于根据岗位的不同采取不同的权限控制，签署保密协议，监控操作情况等措施。如果未能履行这些义务，可能会被追究法律责任或被中止与我们的合作关系。
      </p>
      <p class="weight">3.与第三方签署保密协定</p>
      <p>
        我们会严格要求第三方承担信息保护义务与责任。为此，我们会在合作之前要求第三方签署个人信息处理协议或在双方签署的合作协议中列明有关个人信息保护的条款。协议严格约定第三方对用户信息的保密义务，如第三方有任何违反，我们会要求对方承担相应的法律责任。
      </p>
      <p class="weight">4.信息安全事件处理</p>
      <p>
        我们建立了发生个人信息泄露等安全事件的应急预案，一旦发生，会通过推送通知、公告等形式告知用户。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
      </p>
      <p class="weight">5.信息安全保护提示</p>
      <p>
        （1）您的账号均有安全保护功能，请妥善保管您的账号及您的短信验证码。我们将尽力保障您发送给我们的任何信息的安全性。我方将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。但互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，
        <span class="weight underL"
          >我们强烈建议您使用此类工具时拒绝向他人透露个人账号及短信验证码等，并注意保护信息安全。</span
        >
      </p>
      <p>
        （2）信息披露提示及验证码泄露处理：我们强烈建议您在使用本产品时，务必妥善保管好您的个人信息及其他身份要素。在使用我们的服务时，如果您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息（如银行账号信息、联络方式或者邮寄地址），请您仅在必要的情形下向他人提供。
        <span class="weight underL"
          >如您发现自己的个人信息泄密，尤其是您的账号及短信验证码发生泄露，请您立即联络我们，以便我们采取相应措施。</span
        >
      </p>
      <p>
        （3）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的个人信息的安全性。由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。如果我们的物理、技术、或管理防护设施遭到破坏，导致个人信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
      </p>
      <div class="weight">五、您对您的个人信息的权利</div>
      <p>
        我们非常尊重您对自己的个人信息所享有的权利。我们保障您对个人信息所享有的查询、更正、删除、管理等权利，以使您拥有充分的能力保障您的隐私和安全。
      </p>
      <p>
        请您理解：在您查询、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障账号的安全。
      </p>
      <p>您的权利包括：</p>
      <p class="weight">1.查询您的个人信息</p>
      <p>
        您可通过外研教学评
        https://unilearn.fltrp.com：点击头像-【个人中心】-【个人信息】进行查询。
      </p>
      <p>
        如果您无法通过上述方式查询个人信息，您可以通过本政策文末的联系方式联系我们，除法律法规另有规定的或本政策另有约定外，我们将在核实您的身份后及时响应您的请求。
      </p>
      <p class="weight">2.更正您的个人信息</p>
      <p>您可通过以上查询您的个人信息的路径编辑您的个人信息。</p>
      <p>
        如果您无法通过上述方式更正个人信息，您可以通过本政策文末的联系方式联系我们，除法律法规另有规定的或本政策另有约定外，我们将在核实您的身份后及时响应您的请求。
      </p>
      <p class="weight">3.删除您的个人信息</p>
      <p>
        （1）您可以通过本政策文末的联系方式，联系我们删除您的个人信息。
        在以下情形中，您可以向我们提出删除个人信息的请求：
      </p>
      <p>(a)如果我们处理个人信息的行为违反法律法规；</p>
      <p>(b)如果我们收集、使用您的个人信息，却未征得您的明确同意；</p>
      <p>(c)如果我们处理个人信息的行为严重违反了与您的约定；</p>
      <p>(d)如果您不再使用本产品，或您主动注销了账号；</p>
      <p>(e)如果我们永久不再为您提供产品或服务。</p>
      <p>
        若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除，除非法律法规另有规定，或这些主体已独立获得您的授权。
      </p>
      <p class="weight underL">
        当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份系统更新时删除这些信息。
      </p>
      <p>
        如法律、行政法规规定的保存期限未届满，或者删除个人信息从技术上难以实现的，我们会停止除存储和采取必要的安全保护措施之外的处理。
      </p>
      <p class="weight underL">
        请您注意，若您要求我们删除特定的用户个人信息，可能导致无法继续使用本产品，或导致本产品中的某些业务功能无法实现。
      </p>
      <p class="weight">4.改变您授权同意的范围</p>
      <p>
        您可在您的设备设置中逐项查看您对我们授权权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您可以通过删除信息、关闭设备功能、在软件中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。
        <span class="weight underL">
          您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
        </span>
      </p>
      <p class="weight">5.获取个人信息副本</p>
      <p>
        您可以通过外研教学评https://unilearn.fltrp.com：点击头像-【个人中心】-【个人信息】截图获得您的个人信息副本，您也可以通过文末的联系方式，联系我们索取个人信息副本。
      </p>
      <p class="weight">6.注销账号</p>
      <p>您可以通过以下方式申请账号注销：</p>
      <p>
        （1）您可以通过本政策文末的联系方式联系我们，除法律法规另有规定的或本政策另有约定外，我们将在核实您的身份后及时响应您的请求。您也可以通过【个人中心-其他设置】自行注销账号。
      </p>
      <p class="weight underL">
        （2）注销账号将影响您在本产品的正常使用及学习数据留存。因此我们建议您谨慎考虑账号的注销。
      </p>
      <p>
        （3）您注销账号后，我们将停止为您提供产品和/或服务，并依据您的要求，除法律法规另有规定外，我们将在合理的期限内及时删除您的个人信息或进行匿名化处理。
      </p>
      <p class="weight">7.约束信息系统自动决策结果</p>
      <p>
        在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释或对信息系统自动化决策的结果进行申诉，您可以通过本政策载明的联系方式与我们取得联系或向我们进行申诉，我们也将在不侵害本产品商业秘密或其他用户权益、社会公共利益的前提下提供适当的救济方式。
      </p>
      <p class="weight">8.投诉与举报</p>
      <p>
        我们设置了投诉举报渠道，您的意见将会得到及时的处理。您可以本政策文末载明的联系方式进行投诉和举报。
      </p>
      <p class="weight">9.响应您的请求</p>
      <p>
        如果您无法通过上述方式查询、更正或删除您的个人信息，或您需要查询、更正或删除您在使用我们产品和/或服务时所产生的其他个人信息，或您认为我们存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以通过本政策文末的联系方式与我们联系。
      </p>
      <p>
        为了保障安全，我们可能需要您提供书面请求，或提供您的身份证明文件，我们将在收到您反馈并验证您的身份后的15个工作日内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。
        <span class="weight underL">
          对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
        </span>
      </p>
      <p>按照法律法规及国家标准，在以下情形中，我们可能无法响应您的请求：</p>
      <p>（1）与我们履行法律法规规定的义务相关的；</p>
      <p>（2）与国家安全、国防安全直接相关的；</p>
      <p>（3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>（4）与刑事侦查、起诉、审判和执行判决等直接相关的；</p>
      <p>（5）我们有充分证据表明您存在主观恶意或滥用权利的；</p>
      <p>
        （6）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
      </p>
      <p>
        （7）响应您的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；
      </p>
      <p>（8）涉及商业秘密的。</p>
      <div class="weight">六、个人信息的储存</div>
      <p>
        存储地点：<span class="weight underL">
          我们会按照适用的中国法律法规的要求，将收集的个人信息存储在我们位于中国境内的服务器（包括但不限于云服务器）上。未经您的同意，我们不会将您的信息传输至境外。 </span
        >如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。
      </p>
      <p>
        存储期限：除法律法规另有规定外，我们将按如下期间存储您提供的个人信息：
      </p>
      <p>
        1.我们会储存您提供的个人信息以满足我们的正当业务需求（例如向您提供服务或者来满足相应的法律、税务以及财务的要求）。
      </p>
      <p>
        2.如您不再使用本产品的服务并提出注销申请，我们将在收到您的注销申请之时及时停止收集和处理您的个人信息。当您的账号注销完成后，我们将在合理期限内对您的个人信息进行删除或匿名化处理。
      </p>
      <p>
        3.当我们没有这些需要使用到您提供的个人信息的正当业务需求时或者是中国法律法规规定的留存时间届满时，我们会删除您的个人信息或者对其进行匿名化处理，或者在匿名化或者删除皆不可能实现（例如您的个人信息已经在备份中存储）的时候，安全地储存您的这些个人信息并且将这些个人信息与其他的数据处理区隔开来。
      </p>
      <p>
        4.如果我们终止服务或运营，我们会至少提前三十日逐一送达或以公告的形式向您通知，并在终止服务或运营后的合理期限内对您的个人信息进行删除或匿名化处理。
      </p>
      <div class="weight">七、第三方服务提供商及其服务</div>
      <p>
        本产品可能包含第三方网站、产品和服务，例如部分资源内容由第三方提供。您可以选择是否访问或接受第三方提供的网站、产品和服务。我们会要求第三方通过加强个人信息的安全管理、遵守法律法规和约定的要求，妥善保护您的个人信息。但请您注意，我们无法控制第三方的隐私保护政策，此类第三方不受到本政策的约束。因此，在您向第三方提交个人信息之前，请详细阅读这些第三方的隐私保护政策。如您发现这些第三方创建的网页、提供的产品和服务存在风险时，建议您直接与他们联系获得他们关于隐私政策的保护详情，并停止相关操作以更好地保护您的合法权益。
      </p>
      <p class="weight underL">
        请注意，本政策不适用于其他第三方向您提供的服务。
      </p>
      <div class="weight">八、我们如何保护未成年人的信息</div>
      <p>
        任何未成年人参加网上活动应事先取得父母或其他监护人（以下简称"监护人"）的同意。我们将根据国家相关法律法规的规定保护未成年人的相关信息。
      </p>
      <p class="weight underL">
        特别地，如果您是自行注册成为我们的用户且属于14周岁以下的儿童的，我们为您专门制定了<router-link
          target="_blank"
          to="/childrenPrivacy"
        >
          《儿童隐私保护声明》 </router-link
        >，您应当邀请您的监护人仔细阅读《儿童隐私保护声明》，只有在取得监护人对《儿童隐私保护声明》的同意后，14周岁以下的儿童方可使用我们的产品。如果您是由学校分配账号从而使用我们的产品的且属于14周岁以下的儿童的，您应当在学校的监护下使用我们的产品，您的个人信息将由您的学校向我们提供，我们在您的学校的要求下处理您的个人信息。
      </p>
      <p>
        对于经父母或监护人同意使用本产品而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意的情况下使用、共享、转让或披露此信息。
      </p>
      <p>
        若您是未成年人的监护人，当您对您所监护的未成年人使用本产品和/或服务或其向我们提供的用户信息有任何疑问时，请您及时与我们联系。我们将根据国家相关法律法规及本政策的规定保护未成年人用户信息的保密性及安全性。如果我们发现自己在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
      </p>
      <p>
        如果任何时候监护人需要访问、修改或删除与被监护人有关的个人信息，可根据本政策或《儿童隐私保护声明》的有关说明进行，或通过我们对外公布的相关产品或服务的官方反馈渠道与我们联系。
      </p>
      <div class="weight">九、本政策如何更新</div>
      <p>
        我们可能会根据我们产品或服务更新的情况及法律法规的相关要求适时修订本政策内容，该等修订构成本政策的一部分。未经您明确同意，我们不会削弱您按照本政策所应享有的权利。
      </p>
      <p>
        如该等变更会导致您在本政策项下权利的实质减损，我们将在变更生效前，通过在页面显著位置提示、向您发送电子邮件的方式通知您。本政策所指的重大变更包括但不限于:
      </p>
      <p>
        (1)
        我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
      </p>
      <p>
        (2) 我们在所有权结构、组织架构等方面发生重大变化。如业务调整、
        破产并购等引起的所有者变更等；
      </p>
      <p>(3) 个人信息共享、转让或公开披露的主要对象发生变化；</p>
      <p>(4) 您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p>
        (5) 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
      </p>
      <p>(6) 个人信息安全影响评估报告表明存在高风险时；</p>
      <p>(7) 其他可能对个人信息权益产生重大影响的变化。</p>
      <p class="weight underL">如您不同意该变更，可以选择停止使用本产品。</p>
      <p class="weight underL">
        若您继续使用本产品，即表示同意接受修订后的本政策的内容，但是如果更新的内容需要获取超出本政策收集范围和使用目的的个人敏感信息，仍会再次以显著方式征求您的同意。
      </p>
      <p>
        若适用法律要求提供隐私政策的先前版本以供用户查阅，我们也将遵守相关的法律规定，就本政策的旧版本进行存档，供您查阅。
      </p>
      <div class="weight">十、如何联系我们</div>
      <p>
        1.
        我们设立了个人信息保护部门以及任命了相应的个人信息保护专员，您在使用本产品和/或服务的过程中，如果对本政策有任何的疑问或建议，或您认为您的个人信息没有得到本政策规定的保护，您可以通过以下方式联系我们，我们将真诚地处理您的投诉及建议。
      </p>
      <p>公司名称：外语教学与研究出版社有限责任公司</p>
      <p>联系地址：北京市西三环北路19号外研社大厦研发中心</p>
      <p>联系邮箱：yanfa@fltrp.com</p>
      <p>客服电话：010-88819743</p>
      <p>（悦测评、外研拼读、悦教大讲堂）邮箱：wangjinmeng@fltrp.com</p>
      <p>（悦测评、外研拼读、悦教大讲堂）电话：010-88819188</p>
      <p>
        2.
        一般情况下，我们将在收到您的问题、意见或建议，并验证您的用户身份后的15个工作日内回复。如无法响应您的请求，我们会在法律规定的时限要求内，向您发送通知并解释原因。如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以向履行个人信息保护职责的监管部门进行投诉或举报。
      </p>
      <div class="weight">十一、相关术语及定义</div>
      <p>1. “儿童用户”：指未满14周岁的未成年人用户。</p>
      <p>
        2.“第三方”：指我们因收集个人信息、存储数据、系统维护、服务支持等需求达成合作的供应商或因其他事项与我们建立合作关系的个人、公司企业或其他组织。
      </p>
      <p>3.“用户”或“您”：指使用外研教学评产品和/或服务的用户。</p>
      <p>
        4.“个人信息”：指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。个人信息包括个人基本信息、个人身份信息、个人生物识别信息、网络身份标识信息、个人健康生理信息、个人教育工作信息、个人财产信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑义，个人信息包括但不限于敏感个人信息。
      </p>
      <p>
        5.“敏感个人信息”：指是指一旦被泄露、窃取、篡改、非法使用可能危害个人信息主体人身、财产安全，或导致个人信息主体名誉、身心健康受到损害的个人信息（包括但不限于身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14
        岁以下（含）未成年人的个人信息）。
      </p>
      <p>
        6.“去标识化”：指通过是指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。
      </p>
      <p>7.“匿名化”：指个人信息经过处理无法识别特定自然人且不能复原的过程。</p>
      <p>“境内”或“中国境内”：指中华人民共和国境内。</p>
      <br />
      <div>
        附件《外研教学评个人信息第三方共享清单》：<br />
        说明：<br />
        1、为保障本应用的相关功能的实现与应用安全稳定的运行，我们可能会接入由合作方提供的软件开发包（SDK）、应用程序接口（API）、应用程序插件等代码或通过其他合作方式，以实现相关目的。<br />
        2.我们对接入的相关合作方在目录中列明，合作方具体的处理情况请参见合作方的隐私政策。<br />
        3.请注意，合作方的代码可能因为其版本升级、策略调整等原因导致数据处理类型存在一定变化，请以其公示的官方说明为准。
      </div>
      <br />
      <div>
        SDK名称：微信支付SDK<br />
        使用目的：帮助用户在网站内使用微信支付<br />
        共享信息：支付订单标识、支付时间、支付金额、设备标识信息（IMEI、IMSI、AndroidID、MAC、SIM卡、DeviceID）、硬件序列号、网络信息<br />
        共享方式：SDK本机采集<br />
        第三方主体：深圳市腾讯计算机系统有限公司<br />
        官网链接：https://www.pingxx.com/terms.html#privacy
      </div>
      <br />
      <div>
        SDK名称：支付宝支付SDK<br />
        使用目的：帮助用户在网站内使用支付宝支付<br />
        共享信息：设备标识信息（IMEI、IMSI、AndroidID、MAC地址、SIM卡序列号）、网络信息<br />
        共享方式：SDK本机采集<br />
        第三方主体：支付宝（中国）网络技术有限公司<br />
        官网链接：https://opendocs.alipay.com/open/54/01g6qm
      </div>
      <br />
    </div>
  </div>
</template>
<style scoped>
p {
  text-indent: 2rem;
}
.header {
  background-color: #47475c;
  color: #ffffff;
  overflow: hidden;
}
.headerL {
  float: left;
  color: #ffffff;
  overflow: hidden;
}
.headerL img {
  height: 3.2rem;
  padding: 0.3rem;
  margin-left: 0.5rem;
}
.title {
  font-size: 2rem;
  text-align: center;
  line-height: 60px;
  width: 800px;
  margin: 0 auto;
}
.bg {
  background-color: #eee;
}
.main {
  padding: 1.786em 2.5em;
  background-color: #fff;
  max-width: 1000px;
  margin: 2rem auto;
  line-height: 2.2em;
  margin-bottom: 0;
}
.h1 {
  font-weight: 500;
  font-size: 1.5rem;
  padding: 1.5rem 0;
}
.weight {
  font-weight: 600;
}
ul {
  list-style-type: disc;
  padding-left: 3rem;
}
.pl1 {
  padding-left: 1rem;
}
.underL {
  text-decoration: underline;
}
</style>
